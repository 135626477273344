
#changelog {
    display: none;
    align-items: center;
    color: #555;
    font-size: 16px;
}

#changelog.is-active {
    display: flex;
}

#changelog h1 {
    display: block;
    font-size: 2em;
    font-weight: bold;
    margin-block-start: .67em;
    margin-block-end: .67em;
    color: #333;
}

#changelog h2 {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    margin-block-start: .83em;
    margin-block-end: .83em;
    color: #333;
}

#changelog h3 {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    color: #333;
}

#changelog h4 {
    display: block;
    font-size: 1.00em;
    font-weight: bold;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    color: #333;
}

#changelog h5 {
    display: block;
    font-size: 0.83em;
    font-weight: bold;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    color: #333;
}

#changelog h6 {
    display: block;
    font-size: 0.67em;
    font-weight: bold;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    color: #333;
}

#changelog strong {
    color: #333;
}

#changelog ul {
    padding-left: 40px;
}

#changelog li.level1 {
    list-style: disc;
    font-size: 1em;
}

#changelog li.level2 {
    list-style: circle;
    font-size: 1em;
}

#changelog a {
    color: inherit;
    text-decoration: none;
}

#changelog .is-primary {
    color: #c90000;
}

#changelog .version-release-date {
    font-size: 0.75em;
    color: #555;
}

