/*** MENU CONTEXTUEL ***/
/* Source : https://codepen.io/ryanmorr/pen/JdOvYR */

.context-menu {
    position: absolute;
    width: 200px;
    padding: 2px;
    margin: 0 !important;
    border: 1px solid #bbb;
    background: #eee;
    background: -webkit-linear-gradient(to bottom, #fff 0%, #e5e5e5 100px, #e5e5e5 100%);
    background: linear-gradient(to bottom, #fff 0%, #e5e5e5 100px, #e5e5e5 100%);
    z-index: 1001;
    border-radius: 3px;
    box-shadow: 1px 1px 4px rgba(0,0,0,.2);
    opacity: 0;
    -webkit-transform: translate(0, 15px) scale(.95);
    transform: translate(0, 15px) scale(.95);
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    pointer-events: none;
}

.context-menu-item {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.context-menu-btn {
    display: block;
    color: #444;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 8px;
    border-radius: 3px;
}

button.context-menu-btn {
    background: none;
    line-height: normal;
    overflow: visible;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    width: 100%;
    text-align: left;
}

a.context-menu-btn {
    outline: 0 none;
    text-decoration: none;
}

.context-menu-text {
    margin-left: 25px;
}

.context-menu-btn .fa {
    position: absolute;
    left: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.context-menu-item:hover > .context-menu-btn {
    color: #fff;
    outline: none;
    background-color: #2E3940;
    background: -webkit-linear-gradient(to bottom, #5D6D79, #2E3940);
    background: linear-gradient(to bottom, #5D6D79, #2E3940);
    border: 1px solid #2E3940;
}

.context-menu-item-disabled {
    opacity: .5;
    pointer-events: none;
}

.context-menu-item-disabled .context-menu-btn {
    cursor: default;
}

.context-menu-separator {
    display:block;
    margin: 7px 5px;
    height:1px;
    border-bottom: 1px solid #fff;
    background-color: #aaa;
}

.context-menu-item-submenu::after {
    content: "";
    position: absolute;
    right: 6px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 5px solid transparent;
    border-left-color: #808080;
}

.context-menu-item-submenu:hover::after {
    border-left-color: #fff;
}

.context-menu .context-menu {
    top: 4px;
    left: 99%;
}

.context-menu-show,
.context-menu-item:hover > .context-menu {
    opacity: 1;
    -webkit-transform: translate(0, 0) scale(1);
    transform: translate(0, 0) scale(1);
    pointer-events: auto;
}

.context-menu-item:hover > .context-menu {
    -webkit-transition-delay: 300ms;
    transition-delay: 300ms;
}