div.DTE {
  position: relative;
}
div.DTE div.DTE_Header {
  position: absolute;
  top: 0;
  left: 0;
  height: 52px;
  width: 100%;
  background-color: #e8e9eb;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 16px 10px 2px 16px;
  font-size: 1.3em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div.DTE div.DTE_Body {
  position: relative;
}
div.DTE div.DTE_Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 52px;
  width: 100%;
  background-color: #e8e9eb;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div.DTE div.DTE_Form_Info {
  margin-bottom: 0.5em;
  display: none;
}
div.DTE div.DTE_Form_Content {
  position: relative;
  padding: 10px;
}
div.DTE div.DTE_Form_Error {
  float: left;
  padding: 5px;
  display: none;
  color: #b11f1f;
}
div.DTE button.btn,
div.DTE div.DTE_Form_Buttons button {
  position: relative;
  text-align: center;
  display: block;
  margin-top: 0;
  padding: 0.5em 1em;
  cursor: pointer;
  float: right;
  margin-left: 0.75em;
  color: inherit;
  font-size: 14px;
  border: 1px solid #999;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 1px 1px 3px #ccc;
  -moz-box-shadow: 1px 1px 3px #ccc;
  box-shadow: 1px 1px 3px #ccc;
  background-color: #dcdcdc;
  /* Fallback */
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #dcdcdc 100%);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, #ffffff 0%, #dcdcdc 100%);
  /* FF3.6 */
  background-image: -ms-linear-gradient(top, #ffffff 0%, #dcdcdc 100%);
  /* IE10 */
  background-image: -o-linear-gradient(top, #ffffff 0%, #dcdcdc 100%);
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #ffffff 0%, #dcdcdc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#ffffff', EndColorStr='#dcdcdc');
}
div.DTE button.btn:hover,
div.DTE div.DTE_Form_Buttons button:hover {
  border: 1px solid #666;
  -webkit-box-shadow: 1px 1px 3px #999;
  -moz-box-shadow: 1px 1px 3px #999;
  box-shadow: 1px 1px 3px #999;
  background-color: #cccccc;
  /* Fallback */
  background-image: -webkit-linear-gradient(top, #eaeaea 0%, #cccccc 100%);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, #eaeaea 0%, #cccccc 100%);
  /* FF3.6 */
  background-image: -ms-linear-gradient(top, #eaeaea 0%, #cccccc 100%);
  /* IE10 */
  background-image: -o-linear-gradient(top, #eaeaea 0%, #cccccc 100%);
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #eaeaea 0%, #cccccc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#eaeaea', EndColorStr='#cccccc');
}
div.DTE button.btn:active,
div.DTE div.DTE_Form_Buttons button:active {
  -webkit-box-shadow: inset 1px 1px 3px #999;
  -moz-box-shadow: inset 1px 1px 3px #999;
  box-shadow: inset 1px 1px 3px #999;
}
div.DTE button.btn:focus,
div.DTE div.DTE_Form_Buttons button:focus {
  border: 1px solid #426c9e;
  text-shadow: 0 1px 0 #c4def1;
  background-color: #79ace9;
  /* Fallback */
  background-image: -webkit-linear-gradient(top, #bddef4 0%, #79ace9 100%);
  /* Chrome 10+, Saf5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, #bddef4 0%, #79ace9 100%);
  /* FF3.6 */
  background-image: -ms-linear-gradient(top, #bddef4 0%, #79ace9 100%);
  /* IE10 */
  background-image: -o-linear-gradient(top, #bddef4 0%, #79ace9 100%);
  /* Opera 11.10+ */
  background-image: linear-gradient(to bottom, #bddef4 0%, #79ace9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#bddef4', EndColorStr='#79ace9');
  outline: none;
}
div.DTE button.btn:focus:after,
div.DTE div.DTE_Form_Buttons button:focus:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: block;
  content: " ";
  -webkit-animation-duration: 1s;
  -webkit-animation-name: buttonPulse;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: alternate;
  -moz-animation-duration: 1s;
  -moz-animation-name: buttonPulse;
  -moz-animation-fill-mode: forwards;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -moz-animation-direction: alternate;
  -o-animation-duration: 1s;
  -o-animation-name: buttonPulse;
  -o-animation-fill-mode: forwards;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
  -o-animation-direction: alternate;
  animation-duration: 1s;
  animation-name: buttonPulse;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}
div.DTE.DTE_Action_Remove div.DTE_Body_Content {
  text-align: center;
  padding: 20px 0;
}

@-webkit-keyframes buttonPulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
@-moz-keyframes buttonPulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
@-o-keyframes buttonPulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes buttonPulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
div.DTTT_container {
  float: left;
}

div.DTE_Field input,
div.DTE_Field textarea {
  box-sizing: border-box;
  background-color: white;
  -webkit-transition: background-color ease-in-out .15s;
  transition: background-color ease-in-out .15s;
}
div.DTE_Field input:focus,
div.DTE_Field textarea:focus {
  background-color: #ffffee;
}
div.DTE_Field input[type="color"],
div.DTE_Field input[type="date"],
div.DTE_Field input[type="datetime"],
div.DTE_Field input[type="datetime-local"],
div.DTE_Field input[type="email"],
div.DTE_Field input[type="month"],
div.DTE_Field input[type="number"],
div.DTE_Field input[type="password"],
div.DTE_Field input[type="search"],
div.DTE_Field input[type="tel"],
div.DTE_Field input[type="text"],
div.DTE_Field input[type="time"],
div.DTE_Field input[type="url"],
div.DTE_Field input[type="week"] {
  padding: 5px 4px;
  width: 100%;
}
div.DTE_Field label div.DTE_Label_Info {
  font-size: 0.85em;
  margin-top: 0.25em;
}
div.DTE_Field label div.DTE_Label_Info:empty {
  margin-top: 0;
}
div.DTE_Field div.DTE_Field_Info,
div.DTE_Field div.DTE_Field_Message,
div.DTE_Field div.DTE_Field_Error {
  font-size: 11px;
  line-height: 1em;
  margin-top: 5px;
}
div.DTE_Field div.DTE_Field_Info:empty,
div.DTE_Field div.DTE_Field_Message:empty,
div.DTE_Field div.DTE_Field_Error:empty {
  margin-top: 0;
}
div.DTE_Field div.DTE_Field_Error {
  display: none;
  color: #b11f1f;
}
div.DTE_Field div.multi-value {
  display: none;
  border: 1px dotted #666;
  border-radius: 3px;
  padding: 5px;
  background-color: #fafafa;
  cursor: pointer;
}
div.DTE_Field div.multi-value span {
  font-size: 0.8em;
  line-height: 1.25em;
  display: block;
  color: #666;
}
div.DTE_Field div.multi-value.multi-noEdit {
  border: 1px solid #ccc;
  cursor: auto;
  background-color: #fcfcfc;
}
div.DTE_Field div.multi-value.multi-noEdit:hover {
  background-color: #fcfcfc;
}
div.DTE_Field div.multi-value:hover {
  background-color: #f1f1f1;
}
div.DTE_Field.disabled {
  color: grey;
}
div.DTE_Field.disabled div.multi-value {
  cursor: default;
  border: 1px dotted #aaa;
  background-color: transparent;
}
div.DTE_Field div.multi-restore {
  display: none;
  margin-top: 0.5em;
  font-size: 0.8em;
  line-height: 1.25em;
  color: #3879d9;
}
div.DTE_Field div.multi-restore:hover {
  text-decoration: underline;
  cursor: pointer;
}

div.DTE_Field_Type_textarea textarea {
  padding: 3px;
  width: 100%;
  height: 80px;
}

div.DTE_Field.DTE_Field_Type_date img {
  vertical-align: middle;
  cursor: pointer;
}

div.DTE_Field_Type_checkbox div.DTE_Field_Input > div > div,
div.DTE_Field_Type_radio div.DTE_Field_Input > div > div {
  margin-bottom: 0.25em;
}
div.DTE_Field_Type_checkbox div.DTE_Field_Input > div > div:last-child,
div.DTE_Field_Type_radio div.DTE_Field_Input > div > div:last-child {
  margin-bottom: 0;
}
div.DTE_Field_Type_checkbox div.DTE_Field_Input > div > div label,
div.DTE_Field_Type_radio div.DTE_Field_Input > div > div label {
  margin-left: 0.75em;
  vertical-align: middle;
}

div.DTE_Field_Type_select div.DTE_Field_Input {
  padding-top: 4px;
}

div.DTE_Body {
  padding: 52px 0;
}
div.DTE_Body div.DTE_Body_Content {
  position: relative;
  overflow: auto;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Form_Info {
  padding: 1em 1em 0 1em;
  margin: 0;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Field {
  position: relative;
  zoom: 1;
  clear: both;
  padding: 5px 20%;
  border: 1px solid transparent;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Field:after {
  display: block;
  content: ".";
  height: 0;
  line-height: 0;
  clear: both;
  visibility: hidden;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Field:hover {
  background-color: #f9f9f9;
  border: 1px solid #f3f3f3;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Field > label {
  float: left;
  width: 40%;
  padding-top: 6px;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Field > div.DTE_Field_Input {
  float: right;
  width: 60%;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Field.full {
  padding: 5px 0 5px 20%;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Field.full > label {
  width: 30%;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Field.full > div.DTE_Field_Input {
  width: 70%;
}
div.DTE_Body div.DTE_Body_Content div.DTE_Field.block > div.DTE_Field_Input {
  float: none;
  clear: both;
  width: 100%;
}

html[dir="rtl"] div.DTE_Body div.DTE_Body_Content div.DTE_Field > label {
  float: right;
}
html[dir="rtl"] div.DTE_Body div.DTE_Body_Content div.DTE_Field > div.DTE_Field_Input {
  float: left;
}
html[dir="rtl"] div.DTE div.DTE_Form_Buttons button {
  float: left;
}

@media only screen and (max-width: 768px) {
  div.DTE_Body div.DTE_Body_Content div.DTE_Field {
    padding: 5px 10%;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.full {
    padding: 5px 0 5px 10%;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.full > label {
    width: 35.5%;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.full > div.DTE_Field_Input {
    width: 64.5%;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.block > div.DTE_Field_Input {
    width: 100%;
  }
}
@media only screen and (max-width: 640px) {
  div.DTE_Body div.DTE_Body_Content div.DTE_Field {
    padding: 5px 0;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.full {
    padding: 5px 0%;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.full > label {
    width: 40%;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.full > div.DTE_Field_Input {
    width: 60%;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.block > div.DTE_Field_Input {
    width: 100%;
  }
}
@media only screen and (max-width: 580px) {
  div.DTE_Body div.DTE_Body_Content div.DTE_Field {
    position: relative;
    zoom: 1;
    clear: both;
    padding: 5px 0;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field > label {
    float: none;
    width: auto;
    padding-top: 0;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field > div.DTE_Field_Input {
    float: none;
    width: auto;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.full, div.DTE_Body div.DTE_Body_Content div.DTE_Field.block {
    padding: 5px 0;
  }
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.full > label,
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.full > div.DTE_Field_Input, div.DTE_Body div.DTE_Body_Content div.DTE_Field.block > label,
  div.DTE_Body div.DTE_Body_Content div.DTE_Field.block > div.DTE_Field_Input {
    width: 100%;
  }
}
div.DTE_Bubble {
  position: absolute;
  z-index: 11;
  margin-top: -6px;
  opacity: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner {
  position: absolute;
  bottom: 0;
  border: 1px solid black;
  width: 300px;
  margin-left: -150px;
  background-color: white;
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  border: 1px solid #666;
  padding: 1em;
  background: #fcfcfc;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table {
  width: 100%;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content {
  padding: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field {
  position: relative;
  zoom: 1;
  margin-bottom: 0.5em;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field:last-child {
  margin-bottom: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field > label {
  padding-top: 0;
  margin-bottom: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field > div {
  padding: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field > div input {
  margin: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table div.DTE_Form_Buttons {
  text-align: right;
  margin-top: 1em;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table div.DTE_Form_Buttons button {
  margin-bottom: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Header + div.DTE_Form_Info,
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Header + div.DTE_Bubble_Table {
  padding-top: 42px;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Form_Error {
  float: none;
  display: none;
  padding: 0;
  margin-bottom: 0.5em;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Close {
  position: absolute;
  top: -11px;
  right: -11px;
  width: 22px;
  height: 22px;
  border: 2px solid white;
  background-color: black;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  z-index: 12;
  box-shadow: 2px 2px 6px #111;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Close:after {
  content: '\00d7';
  color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Courier New', Courier, monospace;
  padding-left: 1px;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Close:hover {
  background-color: #092079;
  box-shadow: 2px 2px 9px #111;
}
div.DTE_Bubble div.DTE_Bubble_Triangle {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -6px;
  background-color: white;
  border: 1px solid #666;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
div.DTE_Bubble.below div.DTE_Bubble_Liner {
  top: 10px;
  bottom: auto;
}
div.DTE_Bubble.below div.DTE_Bubble_Triangle {
  top: 4px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

div.DTE_Bubble_Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  /* Fallback */
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* IE10 Consumer Preview */
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* Firefox */
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* Opera */
  background: -webkit-gradient(radial, center center, 0, center center, 497, color-stop(0, rgba(0, 0, 0, 0.3)), color-stop(1, rgba(0, 0, 0, 0.7)));
  /* Webkit (Safari/Chrome 10) */
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* Webkit (Chrome 11+) */
  background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* W3C Markup, IE10 Release Preview */
  z-index: 10;
}
div.DTE_Bubble_Background > div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
}
div.DTE_Bubble_Background > div:not([dummy]) {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled='false');
}

div.DTE_Inline {
  position: relative;
  display: table;
  width: 100%;
}
div.DTE_Inline div.DTE_Inline_Field,
div.DTE_Inline div.DTE_Inline_Buttons {
  display: table-cell;
  vertical-align: middle;
}
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field,
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field {
  padding: 0;
}
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field > label,
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field > label {
  display: none;
}
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="color"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="date"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="datetime"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="datetime-local"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="email"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="month"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="number"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="password"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="search"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="tel"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="text"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="time"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="url"],
div.DTE_Inline div.DTE_Inline_Field div.DTE_Field input[type="week"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="color"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="date"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="datetime"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="datetime-local"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="email"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="month"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="number"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="password"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="search"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="tel"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="text"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="time"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="url"],
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Field input[type="week"] {
  width: 100%;
}
div.DTE_Inline div.DTE_Inline_Field div.DTE_Form_Buttons button,
div.DTE_Inline div.DTE_Inline_Buttons div.DTE_Form_Buttons button {
  margin: -6px 0 -6px 4px;
  padding: 5px;
}
div.DTE_Inline div.DTE_Field input[type="color"],
div.DTE_Inline div.DTE_Field input[type="date"],
div.DTE_Inline div.DTE_Field input[type="datetime"],
div.DTE_Inline div.DTE_Field input[type="datetime-local"],
div.DTE_Inline div.DTE_Field input[type="email"],
div.DTE_Inline div.DTE_Field input[type="month"],
div.DTE_Inline div.DTE_Field input[type="number"],
div.DTE_Inline div.DTE_Field input[type="password"],
div.DTE_Inline div.DTE_Field input[type="search"],
div.DTE_Inline div.DTE_Field input[type="tel"],
div.DTE_Inline div.DTE_Field input[type="text"],
div.DTE_Inline div.DTE_Field input[type="time"],
div.DTE_Inline div.DTE_Field input[type="url"],
div.DTE_Inline div.DTE_Field input[type="week"] {
  margin: -6px 0;
}
div.DTE_Inline div.DTE_Field_Error,
div.DTE_Inline div.DTE_Form_Error {
  font-size: 11px;
  line-height: 1.2em;
  padding: 0;
  margin-top: 10px;
}
div.DTE_Inline div.DTE_Field_Error:empty,
div.DTE_Inline div.DTE_Form_Error:empty {
  margin-top: 0;
}

span.dtr-data div.DTE_Inline {
  display: inline-table;
}

div.DTED_Lightbox_Wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -390px;
  width: 780px;
  height: 100%;
  z-index: 11;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container {
  display: table;
  height: 100%;
  width: 100%;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content {
  position: relative;
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTE {
  background: white;
  border-radius: 6px;
  border: 1px solid #666;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTE div.DTE_Header {
  right: 0;
  width: auto;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTE div.DTE_Body_Content {
  box-sizing: border-box;
  background: #fcfcfc;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTE div.DTE_Footer {
  right: 0;
  width: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTED_Lightbox_Close {
  position: absolute;
  top: -11px;
  right: -11px;
  width: 22px;
  height: 22px;
  border: 2px solid white;
  background-color: black;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  z-index: 12;
  box-shadow: 2px 2px 6px #111;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTED_Lightbox_Close:after {
  content: '\00d7';
  color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Courier New', Courier, monospace;
  padding-left: 1px;
}
div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTED_Lightbox_Close:hover {
  background-color: #092079;
  box-shadow: 2px 2px 9px #111;
}

div.DTED_Lightbox_Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  /* Fallback */
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* IE10 Consumer Preview */
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* Firefox */
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* Opera */
  background: -webkit-gradient(radial, center center, 0, center center, 497, color-stop(0, rgba(0, 0, 0, 0.3)), color-stop(1, rgba(0, 0, 0, 0.7)));
  /* Webkit (Safari/Chrome 10) */
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* Webkit (Chrome 11+) */
  background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  /* W3C Markup, IE10 Release Preview */
  z-index: 10;
}
div.DTED_Lightbox_Background > div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
}
div.DTED_Lightbox_Background > div:not([dummy]) {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled='false');
}

body.DTED_Lightbox_Mobile div.DTED_Lightbox_Background {
  height: 0;
}
body.DTED_Lightbox_Mobile div.DTED_Lightbox_Shown {
  display: none;
}
body.DTED_Lightbox_Mobile div.DTED_Lightbox_Wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: auto;
  height: auto;
  margin-left: 0;
  -webkit-overflow-scrolling: touch;
}
body.DTED_Lightbox_Mobile div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container {
  display: block;
}
body.DTED_Lightbox_Mobile div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper {
  display: block;
}
body.DTED_Lightbox_Mobile div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content {
  border: 4px solid rgba(220, 220, 220, 0.5);
  border-radius: 0;
}
body.DTED_Lightbox_Mobile div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTE {
  border-radius: 0;
  box-shadow: 0 0 5px #555;
  border: 2px solid #444;
}
body.DTED_Lightbox_Mobile div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTE div.DTE_Header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
body.DTED_Lightbox_Mobile div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTE div.DTE_Footer {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
body.DTED_Lightbox_Mobile div.DTED_Lightbox_Wrapper div.DTED_Lightbox_Container div.DTED_Lightbox_Content_Wrapper div.DTED_Lightbox_Content div.DTED_Lightbox_Close {
  top: 11px;
  right: 15px;
}

@media only screen and (max-width: 780px) {
  div.DTED_Lightbox_Wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-left: 0;
  }
}
/*
 * Namespace: DTED - DataTables Editor Display - Envelope
 */
div.DTED_Envelope_Wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  height: 100%;
  z-index: 11;
  display: none;
  overflow: hidden;
}
div.DTED_Envelope_Wrapper div.DTED_Envelope_Shadow {
  position: absolute;
  top: -10px;
  left: 10px;
  right: 10px;
  height: 10px;
  z-index: 10;
  box-shadow: 0 0 20px black;
}
div.DTED_Envelope_Wrapper div.DTED_Envelope_Container {
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  border-left: 1px solid #777;
  border-right: 1px solid #777;
  border-bottom: 1px solid #777;
  box-shadow: 3px 3px 10px #555;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: white;
}
div.DTED_Envelope_Wrapper div.DTED_Envelope_Container div.DTE_Processing_Indicator {
  right: 36px;
}
div.DTED_Envelope_Wrapper div.DTED_Envelope_Container div.DTE_Footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
div.DTED_Envelope_Wrapper div.DTED_Envelope_Container div.DTED_Envelope_Close {
  position: absolute;
  top: 16px;
  right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  z-index: 12;
  text-align: center;
  font-size: 12px;
  background: #F8F8F8;
  background: -webkit-gradient(linear, center bottom, center top, from(#CCC), to(white));
  background: -moz-linear-gradient(top, white, #CCC);
  background: linear-gradient(to bottom, white, #CCC);
  text-shadow: 0 1px 0 white;
  border: 1px solid #999;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0px 0px 1px #999;
  -moz-box-shadow: 0px 0px 1px #999;
  -webkit-box-shadow: 0px 0px 1px #999;
}

div.DTED_Envelope_Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
  /* Fallback */
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%);
  /* IE10 Consumer Preview */
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%);
  /* Firefox */
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%);
  /* Opera */
  background: -webkit-gradient(radial, center center, 0, center center, 497, color-stop(0, rgba(0, 0, 0, 0.1)), color-stop(1, rgba(0, 0, 0, 0.4)));
  /* Webkit (Safari/Chrome 10) */
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%);
  /* Webkit (Chrome 11+) */
  background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%);
  /* W3C Markup, IE10 Release Preview */
}

table.dataTable tbody tr.highlight {
  background-color: #FFFBCC !important;
}
table.dataTable tbody tr.highlight,
table.dataTable tbody tr.noHighlight,
table.dataTable tbody tr.highlight td,
table.dataTable tbody tr.noHighlight td {
  -webkit-transition: background-color 500ms linear;
  -moz-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  -o-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
table.dataTable.stripe tbody tr.odd.highlight, table.dataTable.display tbody tr.odd.highlight {
  background-color: #f9f5c7;
}
table.dataTable.hover tbody tr:hover.highlight,
table.dataTable.hover tbody tr.odd:hover.highlight,
table.dataTable.hover tbody tr.even:hover.highlight, table.dataTable.display tbody tr:hover.highlight,
table.dataTable.display tbody tr.odd:hover.highlight,
table.dataTable.display tbody tr.even:hover.highlight {
  background-color: #f6f2c5;
}
table.dataTable.order-column tbody tr.highlight > .sorting_1,
table.dataTable.order-column tbody tr.highlight > .sorting_2,
table.dataTable.order-column tbody tr.highlight > .sorting_3, table.dataTable.display tbody tr.highlight > .sorting_1,
table.dataTable.display tbody tr.highlight > .sorting_2,
table.dataTable.display tbody tr.highlight > .sorting_3 {
  background-color: #faf6c8;
}
table.dataTable.display tbody tr.odd.highlight > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.highlight > .sorting_1 {
  background-color: #f1edc1;
}
table.dataTable.display tbody tr.odd.highlight > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.highlight > .sorting_2 {
  background-color: #f3efc2;
}
table.dataTable.display tbody tr.odd.highlight > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.highlight > .sorting_3 {
  background-color: #f5f1c4;
}
table.dataTable.display tbody tr.even.highlight > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.highlight > .sorting_1 {
  background-color: #faf6c8;
}
table.dataTable.display tbody tr.even.highlight > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.highlight > .sorting_2 {
  background-color: #fcf8ca;
}
table.dataTable.display tbody tr.even.highlight > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.highlight > .sorting_3 {
  background-color: #fefacb;
}
table.dataTable.display tbody tr:hover.highlight > .sorting_1,
table.dataTable.display tbody tr.odd:hover.highlight > .sorting_1,
table.dataTable.display tbody tr.even:hover.highlight > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.highlight > .sorting_1,
table.dataTable.order-column.hover tbody tr.odd:hover.highlight > .sorting_1,
table.dataTable.order-column.hover tbody tr.even:hover.highlight > .sorting_1 {
  background-color: #eae6bb;
}
table.dataTable.display tbody tr:hover.highlight > .sorting_2,
table.dataTable.display tbody tr.odd:hover.highlight > .sorting_2,
table.dataTable.display tbody tr.even:hover.highlight > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.highlight > .sorting_2,
table.dataTable.order-column.hover tbody tr.odd:hover.highlight > .sorting_2,
table.dataTable.order-column.hover tbody tr.even:hover.highlight > .sorting_2 {
  background-color: #ece8bd;
}
table.dataTable.display tbody tr:hover.highlight > .sorting_3,
table.dataTable.display tbody tr.odd:hover.highlight > .sorting_3,
table.dataTable.display tbody tr.even:hover.highlight > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.highlight > .sorting_3,
table.dataTable.order-column.hover tbody tr.odd:hover.highlight > .sorting_3,
table.dataTable.order-column.hover tbody tr.even:hover.highlight > .sorting_3 {
  background-color: #efebbf;
}

div.DTE div.editor_upload {
  padding-top: 4px;
}
div.DTE div.editor_upload div.eu_table {
  display: table;
  width: 100%;
}
div.DTE div.editor_upload div.row {
  display: table-row;
}
div.DTE div.editor_upload div.cell {
  display: table-cell;
  position: relative;
  width: 50%;
  vertical-align: top;
}
div.DTE div.editor_upload div.cell + div.cell {
  padding-left: 10px;
}
div.DTE div.editor_upload div.row + div.row div.cell {
  padding-top: 10px;
}
div.DTE div.editor_upload button.btn,
div.DTE div.editor_upload input[type=file] {
  width: 100%;
  height: 2.3em;
  font-size: 0.8em;
  text-align: center;
  line-height: 1em;
}
div.DTE div.editor_upload input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}
div.DTE div.editor_upload div.drop {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 3px dashed #ccc;
  border-radius: 6px;
  min-height: 4em;
  color: #999;
  padding-top: 3px;
  text-align: center;
}
div.DTE div.editor_upload div.drop.over {
  border: 3px dashed #111;
  color: #111;
}
div.DTE div.editor_upload div.drop span {
  max-width: 75%;
  font-size: 0.85em;
  line-height: 1em;
}
div.DTE div.editor_upload div.rendered img {
  max-width: 8em;
  margin: 0 auto;
}
div.DTE div.editor_upload.noDrop div.drop {
  display: none;
}
div.DTE div.editor_upload.noDrop div.row.second {
  display: none;
}
div.DTE div.editor_upload.noDrop div.rendered {
  margin-top: 10px;
}
div.DTE div.editor_upload.noClear div.clearValue button {
  display: none;
}
div.DTE div.editor_upload.multi div.cell {
  display: block;
  width: 100%;
}
div.DTE div.editor_upload.multi div.cell div.drop {
  min-height: 0;
  padding-bottom: 5px;
}
div.DTE div.editor_upload.multi div.clearValue {
  display: none;
}
div.DTE div.editor_upload.multi ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
div.DTE div.editor_upload.multi ul li {
  position: relative;
  margin-top: 0.5em;
}
div.DTE div.editor_upload.multi ul li:first-child {
  margin-top: 0;
}
div.DTE div.editor_upload.multi ul li img {
  vertical-align: middle;
}
div.DTE div.editor_upload.multi ul li button {
  position: absolute;
  width: 40px;
  right: 0;
  top: 50%;
  margin-top: -1.5em;
}

div.editor-datetime {
  position: absolute;
  background-color: white;
  z-index: 2050;
  border: 1px solid #ccc;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
  padding: 0 20px 6px 20px;
  width: 275px;
}
div.editor-datetime div.editor-datetime-title {
  text-align: center;
  padding: 5px 0px 3px;
}
div.editor-datetime table {
  border-spacing: 0;
  margin: 12px 0;
  width: 100%;
}
div.editor-datetime table.editor-datetime-table-nospace {
  margin-top: -12px;
}
div.editor-datetime table th {
  font-size: 0.8em;
  color: #777;
  font-weight: normal;
  width: 14.285714286%;
  padding: 0 0 4px 0;
  text-align: center;
}
div.editor-datetime table td {
  font-size: 0.9em;
  color: #444;
  padding: 0;
}
div.editor-datetime table td.selectable {
  text-align: center;
  background: #f5f5f5;
}
div.editor-datetime table td.selectable.disabled {
  color: #aaa;
  background: white;
}
div.editor-datetime table td.selectable.disabled button:hover {
  color: #aaa;
  background: white;
}
div.editor-datetime table td.selectable.now {
  background-color: #ddd;
}
div.editor-datetime table td.selectable.now button {
  font-weight: bold;
}
div.editor-datetime table td.selectable.selected button {
  background: #4E6CA3;
  color: white;
  border-radius: 2px;
}
div.editor-datetime table td.selectable button:hover {
  background: #ff8000;
  color: white;
  border-radius: 2px;
}
div.editor-datetime table td.editor-datetime-week {
  font-size: 0.7em;
}
div.editor-datetime table button {
  width: 100%;
  box-sizing: border-box;
  border: none;
  background: transparent;
  font-size: inherit;
  color: inherit;
  text-align: center;
  padding: 4px 0;
  cursor: pointer;
  margin: 0;
}
div.editor-datetime table button span {
  display: inline-block;
  min-width: 14px;
  text-align: right;
}
div.editor-datetime table.weekNumber th {
  width: 12.5%;
}
div.editor-datetime div.editor-datetime-calendar table {
  margin-top: 0;
}
div.editor-datetime div.editor-datetime-label {
  position: relative;
  display: inline-block;
  height: 30px;
  padding: 5px 6px;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
}
div.editor-datetime div.editor-datetime-label:hover {
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: #f5f5f5;
}
div.editor-datetime div.editor-datetime-label select {
  position: absolute;
  top: 6px;
  left: 0;
  cursor: pointer;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
}
div.editor-datetime.horizontal {
  width: 550px;
}
div.editor-datetime.horizontal div.editor-datetime-date,
div.editor-datetime.horizontal div.editor-datetime-time {
  width: 48%;
}
div.editor-datetime.horizontal div.editor-datetime-time {
  margin-left: 4%;
}
div.editor-datetime div.editor-datetime-date {
  position: relative;
  float: left;
  width: 100%;
}
div.editor-datetime div.editor-datetime-time {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
}
div.editor-datetime div.editor-datetime-time > span {
  vertical-align: middle;
}
div.editor-datetime div.editor-datetime-time th {
  text-align: left;
}
div.editor-datetime div.editor-datetime-time div.editor-datetime-timeblock {
  display: inline-block;
  vertical-align: middle;
}
div.editor-datetime div.editor-datetime-iconLeft,
div.editor-datetime div.editor-datetime-iconRight,
div.editor-datetime div.editor-datetime-iconUp,
div.editor-datetime div.editor-datetime-iconDown {
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.3;
  overflow: hidden;
  box-sizing: border-box;
}
div.editor-datetime div.editor-datetime-iconLeft:hover,
div.editor-datetime div.editor-datetime-iconRight:hover,
div.editor-datetime div.editor-datetime-iconUp:hover,
div.editor-datetime div.editor-datetime-iconDown:hover {
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #f0f0f0;
  opacity: 0.6;
}
div.editor-datetime div.editor-datetime-iconLeft button,
div.editor-datetime div.editor-datetime-iconRight button,
div.editor-datetime div.editor-datetime-iconUp button,
div.editor-datetime div.editor-datetime-iconDown button {
  border: none;
  background: transparent;
  text-indent: 30px;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
div.editor-datetime div.editor-datetime-iconLeft {
  position: absolute;
  top: 5px;
  left: 5px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==");
}
div.editor-datetime div.editor-datetime-iconRight {
  position: absolute;
  top: 5px;
  right: 5px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=");
}
div.editor-datetime div.editor-datetime-iconUp {
  height: 20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAALCAMAAABf9c24AAAAFVBMVEX///99fX1+fn57e3t6enoAAAAAAAC73bqPAAAABnRSTlMAYmJkZt92bnysAAAAL0lEQVR4AWOgJmBhxCvLyopHnpmVjY2VCadeoCxIHrcsWJ4RlyxCHlMWCTBRJxwAjrIBDMWSiM0AAAAASUVORK5CYII=");
}
div.editor-datetime div.editor-datetime-iconDown {
  height: 20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAALCAMAAABf9c24AAAAFVBMVEX///99fX1+fn57e3t6enoAAAAAAAC73bqPAAAABnRSTlMAYmJkZt92bnysAAAAMElEQVR4AWOgDmBiRQIsmPKMrGxQgJDFlEfIYpoPk8Utz8qM232MYFfhkQfKUg8AANefAQxecJ58AAAAAElFTkSuQmCC");
}

div.editor-datetime-error {
  clear: both;
  padding: 0 1em;
  max-width: 240px;
  font-size: 11px;
  line-height: 1.25em;
  text-align: center;
  color: #b11f1f;
}

div.DTE div.DTE_Processing_Indicator {
  position: absolute;
  top: 17px;
  right: 9px;
  height: 2em;
  width: 2em;
  z-index: 20;
  font-size: 12px;
  display: none;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
div.DTE.processing div.DTE_Processing_Indicator {
  display: block;
}
div.DTE.processing div.DTE_Field div.DTE_Processing_Indicator {
  display: none;
}
div.DTE div.DTE_Field div.DTE_Processing_Indicator {
  top: 13px;
  right: 0;
  font-size: 8px;
}
div.DTE.DTE_Inline div.DTE_Processing_Indicator {
  top: 5px;
  right: 6px;
  font-size: 6px;
}
div.DTE.DTE_Bubble div.DTE_Processing_Indicator {
  top: 10px;
  right: 14px;
  font-size: 8px;
}
div.DTE div.DTE_Processing_Indicator span,
div.DTE div.DTE_Processing_Indicator:before,
div.DTE div.DTE_Processing_Indicator:after {
  display: block;
  background: black;
  width: 0.5em;
  height: 1.5em;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-animation: editorProcessing 0.9s infinite ease-in-out;
  animation: editorProcessing 0.9s infinite ease-in-out;
}
div.DTE div.DTE_Processing_Indicator:before,
div.DTE div.DTE_Processing_Indicator:after {
  position: absolute;
  top: 0;
  content: '';
}
div.DTE div.DTE_Processing_Indicator:before {
  left: -1em;
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
div.DTE div.DTE_Processing_Indicator span {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}
div.DTE div.DTE_Processing_Indicator:after {
  left: 1em;
}
@-webkit-keyframes editorProcessing {
  0%,
		80%,
		100% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1.5);
  }
}
@keyframes editorProcessing {
  0%,
		80%,
		100% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1.5);
  }
}
