table.DTFC_Cloned thead,
table.DTFC_Cloned tfoot {
  background-color: white;
}

div.DTFC_Blocker {
  background-color: white;
}

div.DTFC_LeftWrapper table.dataTable,
div.DTFC_RightWrapper table.dataTable {
  margin-bottom: 0;
  z-index: 2;
}
div.DTFC_LeftWrapper table.dataTable.no-footer,
div.DTFC_RightWrapper table.dataTable.no-footer {
  border-bottom: none;
}
